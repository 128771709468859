import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FrameLayout from "../../core/FrameLayout/FrameLayout";
import axios from 'axios';
// REFER LINK: https://github.com/palerdot/react-d3-speedometer
import statusSpeedoMeter from "../../components/speedoMeter/statusSpeedoMeter";
import { useLocation } from 'react-router-dom';
import HeaderMenu from "../../core/HeaderMenu/HeaderMenu";
import LinkedInTracking from "../LinkedInTracking";

const Wrapper = styled.div`
   ${(props) => props.theme.Results?.Wrapper};
`;
const LeftContainer = styled.div`
   ${(props) => props.theme.Results?.LeftContainer};
`;
const TitleWrapper = styled.div`
   ${(props) => props.theme.Results?.TitleWrapper};
`;
const Title = styled.p`
   ${(props) => props.theme.Results?.Title};
`;
const SubTitle = styled.p`
   ${(props) => props.theme.Results?.SubTitle};
`;
const ButtonWrapper = styled.div`
   ${(props) => props.theme.Results?.ButtonWrapper};
`;
const Button = styled.button`
   ${(props) => props.theme.Results?.Button};
`;
const Container = styled.div`
  ${(props) => props.theme.Home?.Container};
`;

function getCurrentDimension() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
}

const bg_app =
  window.innerWidth > 576
    ? "/images/bg_health_check.jpg"
    : "/images/bg_health_check_mobile.jpg";

const Results = (props) => {
    // const { setIsResultScreenShow, setIsInfoScreenShow, resultData } = props;
    // const resultJson = resultData;
    const [score, setScore] = useState(0);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [scoreTitle, setScoreTitle] = useState('');
    const [scoreDesc, setScoreDesc] = useState('');

    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const resultDataParm = queryParams.get('resultData');
    const { questionData, ipInfo } = location.state || {};
    const resultJson = questionData;
    console.log(resultJson);

    // Status
    const marks = {
        0: '0',
        20: '20',
        40: '40',
        60: '60',
        80: '80',
        100: '100',
    };

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })

    }, [screenSize])

    useEffect(() => {
        try {
            const scoreCal = parseInt(resultJson.question_1.point) + parseInt(resultJson.question_2.point) + parseInt(resultJson.question_3.point)
                + parseInt(resultJson.question_4.point) + parseInt(resultJson.question_5.point) + parseInt(resultJson.question_6.point)
                + parseInt(resultJson.question_7.point) + parseInt(resultJson.question_8.point);

            setScore(scoreCal);

            if (scoreCal >= 89) {
                setScoreTitle('Fantastic News! Your Current Strata Arrangement is Excellent!');
                setScoreDesc('It looks like your current Management arrangement is already in great shape.\nHowever, if you have any questions or need further assistance, we’re here to help.\nFeel free to reach out to us anytime!');
            } else if (scoreCal >= 69 && scoreCal <= 88) {
                setScoreTitle('Looking Good, but there’s Room for Improvement.');
                setScoreDesc('Your Management arrangement is pretty good, but there’s always room for a improvement. If you’d like to discuss how to take things to the next level, we’re here to help. Don’t hesitate to reach out to us!');
            } else if (scoreCal >= 50 && scoreCal <= 68) {
                setScoreTitle('Needs Improvement: Let’s Discuss How to Improve Your Strata');
                setScoreDesc('Your Management arrangement could use some improvements. Let’s discuss how we can help make it better. Please reach out to us for further assistance.');
            } else {
                setScoreTitle('Hmm.. Looks Like You May Need a Hand!');
                setScoreDesc('Your scheme could use some urgent assistance. We’ll be in touch to help get everything sorted!');
            }

            //Save Data on Server Database
            if (score > 0)
                saveInfoOnServer();

        } catch (err) {
            console.log(err);
        }
    }, [resultJson, score]);

    const saveInfoOnServer = () => {
        //APi Call
        if (resultJson !== null) {
            
            const URL_DEMO = 'https://stratahealth.aptoms.com';
            // const URL_Client = 'https://stratahealthcheck.com.au';

            const URL = URL_DEMO;

            const data = {
                name: resultJson.name,
                email: resultJson.email,
                phone: resultJson.phone,
                score: score,
                answerJson: JSON.stringify(resultJson),
                location: ipInfo,
            };
            const formBody = Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');

            axios.post(`${URL}/api/user.php`, formBody, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then(response => {
                // Handle success
                console.log(response);
            }).catch(error => {
                // Handle error
                console.error(error);
            });
        }
    }

    const handleGetTouch = () => {
        // window.location.href = 'https://calendly.com/tstewart-sskb';
        window.open('https://calendly.com/tstewart-sskb', '_blank');
    }

    const handleGetQuote = () => {
        window.open('https://sskb.com.au/proposal/', '_blank');
        // setIsInfoScreenShow(false);
        // setIsResultScreenShow(false);
    };

    return (
        <Container
            style={{
                backgroundImage: `url(${bg_app})`,
                backgroundSize: "cover",
                overflow: "auto",
            }}
        >
            <LinkedInTracking />
            <HeaderMenu />
            <FrameLayout className="row" minHeight={43} styles={{ marginLeft: '8%', marginRight: '8%', marginTop: '6rem', display: 'flex' }}>
                <Wrapper>
                    <LeftContainer> {/* className="col col-xl-8 col-lg-8 col-md-8 col-sm-7 col-7 col-xm-12" */}
                        <TitleWrapper>
                            <Title>{scoreTitle}</Title>
                            <SubTitle>{scoreDesc}</SubTitle>
                        </TitleWrapper>
                        {statusSpeedoMeter(score, 100, marks)}
                        {/* <statusSpeedoMeter value={value} max={100} marks={marks} onChange={setValue} /> */}
                        <ButtonWrapper>
                            <Button type="button" onClick={handleGetTouch}>Schedule a Call</Button>
                            <Button type="button" onClick={handleGetQuote}>Request a Quote</Button>
                        </ButtonWrapper>
                    </LeftContainer>
                </Wrapper>
            </FrameLayout>
        </Container>
    );
};

export default Results;