import React, { useState } from "react";
import styled from "styled-components";
import "react-form-wizard-component/dist/style.css";
import FrameLayout from "../../core/FrameLayout/FrameLayout";
import Validation from '../../utils/Validation';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import HeaderMenu from "../../core/HeaderMenu/HeaderMenu";
import LinkedInTracking from "../LinkedInTracking";

//https://react-form-wizard-component-document.netlify.app/docs/demos/validate-tab

const Card = styled.div`
   ${(props) => props.theme.Information?.Card};
`;
const CardHeader = styled.div`
   ${(props) => props.theme.Information?.CardHeader};
`;
const CardBody = styled.div`
   ${(props) => props.theme.Information?.CardBody};
`;
const QuestionAnswerWrapper = styled.div`
   ${(props) => props.theme.Information?.QuestionAnswerWrapper};
`;
const FieldContainer = styled.div`
    ${(props) => props.theme.Information?.FieldContainer};
`;
const Question = styled.label`
   ${(props) => props.theme.Information?.Question};
`;

const AnswerWrapper = styled.div`
    ${(props) => props.theme.Information?.AnswerWrapper};
`;

const AnswerField = styled.input`
   ${(props) => props.theme.Information?.AnswerField};
`;

const ButtonWrapper = styled.div`
   ${(props) => props.theme.Information?.ButtonWrapper};
`;

const Button = styled.button`
   ${(props) => props.theme.Information?.Button};
`;

const Title = styled.label`
    ${(props) => props.theme.Information?.Title};
`;

const Container = styled.div`
  ${(props) => props.theme.Home?.Container};
`;

const bg_app =
  window.innerWidth > 576
    ? "/images/bg_health_check.jpg"
    : "/images/bg_health_check_mobile.jpg";

const InformationScreen = (props) => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const navigate = useNavigate();

    const [fullNameError, setFullNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isEmailError, setIsValidEmailError] = useState(false);

    const location = useLocation();
    const { floatProgress, questionData: QuestionData, ipInfo } = location.state || {};
    const [questionData, setQuestionData] = useState(QuestionData);
    const [progressStatus, setProgressStatus] = useState(floatProgress);

 const handleNextQuestion = () => {

        let progress = parseFloat(progressStatus) + 11.12;
        setProgressStatus(parseFloat(progress).toFixed(2));
        if (parseFloat(progress).toFixed(2) >= 100) {
            var error = false;
            if (questionData.name === '') {
                setFullNameError("Please enter name.");
                error = true;
            }
            if (questionData.email === '') {
                setEmailError("Please enter email address.");
                setIsValidEmailError(true);
                error = true;
            } else if (!Validation.isEmailValid(questionData.email)) {
                setEmailError("Please enter valid email address.");
                setIsValidEmailError(true);
                error = true;
            } else {
                setEmailError("");
                setIsValidEmailError(false);
            }
            if (!error) {
                // navigate(`/results?resultData=${JSON.stringify(questionData)}`);
                navigate('/results', { state: { questionData, ipInfo } });
            }
        } else {
            setQuestionIndex(parseInt(questionIndex) + 1);
            // setSelectedOption('Unsure');
        }
    };

    const handlePrevQuestion = () => {
        setQuestionIndex(parseInt(questionIndex) - 1);

        let progress = parseFloat(progressStatus) - 11.12;
        setProgressStatus(parseFloat(progress).toFixed(2));

    }

    // Function to handle the change in selected option
    const handleQuestionAnsChange = (questionId, answerJson) => {
        setQuestionData({
            ...questionData, [questionId]: answerJson,
        });
    };

    return (
        <Container
            style={{
                backgroundImage: `url(${bg_app})`,
                backgroundSize: "cover",
                overflow: "auto",
            }}
        >
            <LinkedInTracking />
            <HeaderMenu />
            <FrameLayout className="row" marginBottom={'2rem'} styles={{ marginTop: '8%', height: 'fit-content', position: 'relative' }}>
                <Card>
                    <CardHeader>
                        <Question>{'Complete your details to view the results of your \n Strata Health Check'}</Question>
                    </CardHeader>
                    <CardBody>
                        <QuestionAnswerWrapper>
                            <FieldContainer style={{ order: 1 }}>
                                <Title>Name: </Title>
                                <AnswerWrapper>
                                    <AnswerField type="text" onChange={(e) => handleQuestionAnsChange('name', e.target.value)} />
                                    {fullNameError && !questionData.name && <label id="full_name_error" className="form-control-error">{fullNameError}</label>}
                                </AnswerWrapper>
                            </FieldContainer>
                            <FieldContainer style={{ order: 2 }}>
                                <Title>Email: </Title>
                                <AnswerWrapper>
                                    <AnswerField type="email" onChange={(e) => handleQuestionAnsChange('email', e.target.value)} />
                                    {isEmailError && <label id="email_error" className="form-control-error">{emailError}</label>}
                                </AnswerWrapper>
                            </FieldContainer>
                            <FieldContainer style={{ order: 3 }}>
                                <Title>Phone</Title>
                                <AnswerWrapper>
                                    <AnswerField type="text" onChange={(e) => handleQuestionAnsChange('phone', e.target.value)} />
                                </AnswerWrapper>
                            </FieldContainer>
                        </QuestionAnswerWrapper>
                        <ButtonWrapper>
                            {questionIndex > 0 && (<Button type="button" onClick={() => handlePrevQuestion(progressStatus)}>Previous</Button>)}
                            <Button type="button" onClick={() => handleNextQuestion(progressStatus)}>Next</Button>
                        </ButtonWrapper>
                    </CardBody>
                    {/* add style */}
                    <style>
                        {`
                        .form-control-error {
                            color: red;
                            font-size: 1rem;
                            padding: 0.2rem 0.5rem;
                        }
                        .error-astreisk {
                            color: red;
                            font-size: 20px;
                        }
                    `}
                    </style>
                </Card>
            </FrameLayout>
        </Container>
    )
};

export default InformationScreen;