import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

const MenuContainer = styled.div`
   position: fixed;
   width: 100%;
   z-index: 1000;
   min-height: 6.25rem;
`;

const MenuWrapper = styled.div`
   max-width: 1850px;
   font-size: 1rem;
   display: flex;
   padding: 1rem;
   margin: 0 auto;
`;
const Logo = styled.img`
   margin-left: 2rem;
   width: 10%;
   cursor: pointer;
   @media (max-width: 991px) {
      width: 14%;
   }
   @media (max-width: 767px) {
      width: 15%;
      margin-left: 1rem;
   }
   @media (max-width: 576px) {
      width: 25%;
      margin-left: 1rem;
   }
`;
const MenuContent = styled.div`
   display: flex;
   margin: 0 auto;
`;
const ButtonWrapper = styled.div`
   display: none; /* flex; */
   align-items: center;
`;
const Button = styled.button`
   cursor: pointer;
   display: block;
   width: 181px;
   color: ${(props) => props.theme.txtButton};
   background-color: ${(props) => props.theme.bgButton};
   border-color: ${(props) => props.theme.bgButton};
   box-shadow: none;
   border: 1px solid transparent;
   padding: 0.875rem;
   font-size: 0.875rem;
   font-weight: 600;
   line-height: normal;
   border-radius: 0.5rem;
   margin-right: 0.5rem;
`;

const img_logo = '/images/img_logo_sskb_white.png';

const HeaderMenu = () => {
   const history = useNavigate();

   const handleLogoClick = (event, id) => {
      history(`/`);
      window.location.reload(false);
   };

   const handleExpertTalk = (event, id) => {
      history(`/`);
   };

   const handleSignIn = (event, id) => {
      history(`/`);
   };

   return (
      <MenuContainer>
         <MenuWrapper>
            <Logo src={img_logo} onClick={handleLogoClick} alt="logo" />
            <MenuContent></MenuContent>
            <ButtonWrapper>
               <Button type="button" onClick={handleExpertTalk}>TALK TO AN EXPERT</Button>
               <Button type="button" onClick={handleSignIn}>SIGN IN</Button>
            </ButtonWrapper>
         </MenuWrapper >
      </MenuContainer>
   );
};

export default HeaderMenu;