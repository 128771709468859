import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './statusSpeedoMeterStyle.css';

const CustomHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  const leftMargin = (value == 0) ? '45px' : '55.5px';

  return (
    <div className='scoreContainer'>
      <div {...restProps} className="custom-handle" />
      <div className="score-arrow" style={{ left: `calc(${value}% - ${leftMargin})` }}>
        <div className="arrow-up"></div>
        <div className="score-text">Your Score</div>
      </div>
    </div>
  );
};

const statusSpeedoMeter = (value, max, marks) => {
  return (
    <div className="gauge-container">
      <Slider
        value={value}
        min={0}
        max={100}
        marks={marks}
        step={1}
        handleRender={() => <CustomHandle value={value}/>}
        className="custom-slider"
      />
      <div className="gauge-label"></div>
    </div>
  );
};

export default statusSpeedoMeter;