import { css } from 'styled-components';

// Theme
export const ResultsTheme = {

    name: 'Results',
    Container: css`
        width: 100%;
        height: 100%;
        background: #ebeef1;
        overflow: auto;
    `,
    Wrapper: css`
        display: flex;
        width: 100%;
        justify-content: center;
        margin-left: 11%;
        margin-right: 11%;
        text-align: center;
        @media (mac-width: 1200px) {
            margin-left: 3.5%;
            margin-right: 3.5%;
        }
        @media (max-width: 991px) {
            display: block;
            margin-left: 4%;
            margin-right: 4%;
        }
        @media (max-width: 991px) {
            display: block;
            margin-left: 2%;
            margin-right: 2%;
        }
    `,
    TitleWrapper: css`
        color: #ffffff;
        text-align: center;
    `,
    Title: css`
        font-size: 2.6rem;
        line-height: 2.7rem;
        font-weight: bold;
        margin: 0px;
        white-space: pre-wrap;
        @media (max-width: 1500px) {
            font-size: 2rem;
            line-height: 2.2rem;
        }
        @media (max-width: 1200px) {
            font-size: 1.8rem;
            line-height: 2.1rem;
        }
        @media (max-width: 991px) {
            font-size: 1.3rem;
            line-height: 1.5rem;
        }
        @media (max-width: 576px) {
            font-size: 1.5rem;
            line-height: 1.4rem;
        }

    `,
    SubTitle: css`
        // font-size: 1.75rem;
        // font-weight: 400;
        // line-height: 1.8rem;
        // width: 95%;
        // color: ${(props) => props.theme.secondary};
        // margin: 20px 0;
        // @media (max-width: 1200px) {
        //     font-size: 1.5rem;
        // }
        // @media (max-width: 991px) {
        //     width: 100%;
        // }

        font-size: 1.75rem;
        font-weight: 400;
        line-height: 1.8rem;
        width: 95%;
        color: ${(props) => props.theme.secondary};
        margin: 20px 0;
        @media (max-width: 1200px) {
            font-size: 1.5rem;
            line-height: 1.6rem;
        }
        @media (max-width: 991px) {
            font-size: 1.2rem;
            width: 100%;
            margin: 25px 0;
            line-height: 1.5rem;
        }
        @media (max-width: 767px) {
            font-size: 1.2rem;
            width: 100%;
            margin: 10px 0 0;
            line-height: 1.5rem;
        }
        @media (max-width: 576px) {
            font-size: 1.0rem;
            line-height: 1.1rem;
        }
    `,
    ButtonWrapper: css`
        display: flex;
        justify-content: center;
        margin-top: 3.1rem;
        margin-bottom: 1rem;
        gap: 1rem;
        @media (max-width: 991px) {
            margin-top: 1rem;
        }
        @media (max-width: 767px) {
            margin-top: 3.7rem;
        }
        @media (max-width: 576px) {
            display: block;
            margin-top: 4rem;
            text-align: -webkit-center;
        }
    `,
    Button: css`
        cursor: pointer;
        display: block;
        color: ${(props) => props.theme.txtButton};
        background-color: ${(props) => props.theme.bgButton};
        border-color: ${(props) => props.theme.bgButton};
        box-shadow: none;
        border: 1px solid transparent;
        padding: 0.2rem 1.5rem;
        font-size: 1.5rem;
        line-height: normal;
        border-radius: 0.2rem;
        @media (max-width: 991px) {
            font-size: 1.2rem;
        }
        @media (max-width: 767px) {
            font-size: 1.2rem;
        }
        @media (max-width: 576px) {
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
        @media (max-width: 467px) {
            font-size: 1.2rem;
        }
    `,
    DesktopRightContainer: css`
        width: 50%;
        gap: 2rem;
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 991px) {
           display: none;
        //    width: auto;
        //    position: absolute;
        //    top: 15%;
        //    right: 10%;
        //    left: 10%;
        }
        @media (max-width: 576px) {
           right: 5%;
           left: 5%;
        }
        @media (max-width: 450px) {
           right: 2%;
           left: 2%;
        }
    `,
    ResultsWrapper: css`
        display: table;
        margin: 0 auto;
        padding: 30px 0px;
        border-radius: 6px;
        /* box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.32), 0 1px 9px -1px #15222e;
        background: #282c34; */
    `,
    SpeedometerWrapper: css`
        width: 450px;
        height: 250px;
        @media (max-width: 991px) {
           width: 400px;
           height: 250px;
        }
        @media (max-width: 767px) {
           width: 350px;
           height: 200px;
        }
        @media (max-width: 450px) {
           width: 270px;
           height: 160px;
        }
        @media (max-width: 300px) {
            width: 220px;
            height: 145px;
         }
    `,
    ScoreTitle: css`
        font-size: 1.5rem;
        font-weight: 700;
        white-space: pre-wrap;
        text-align: center;
        color: #ffffff;
    `,

}