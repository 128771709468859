import React from "react";
import { ThemeProvider } from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import configuration from "./configuration";
import Home from "./modules/Home";
import Results from "./modules/Content/ResultScreen";
import InformationScreen from "./modules/Content/InformationScreen";

const App = (props) => {
   const { theme } = configuration;

   return (
      <>
         <ThemeProvider theme={theme}>
            <Routes>
               <Route exact path="/" element={<Home />} />
               <Route exact path="/info" element={<InformationScreen />} />
               <Route exact path="/results" element={<Results />} />
            </Routes>
         </ThemeProvider>
      </>
   );
};

export default App;
