import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import HeaderMenu from "../core/HeaderMenu/HeaderMenu";
import LandingScreen from "../modules/Content/LandingScreen";
import QuestionScreen from "../modules/Content/QuestionScreen";
import LinkedInTracking from "./LinkedInTracking";
// import InformationScreen from "../modules/Content/InformationScreen";
// import ResultScreen from "../modules/Content/ResultScreen";

const Container = styled.div`
  ${(props) => props.theme.Home?.Container};
`;

const bg_app =
  window.innerWidth > 576
    ? "/images/bg_health_check.jpg"
    : "/images/bg_health_check_mobile.jpg";

const Home = (props) => {
  const [isInfoScreenShow, setIsInfoScreenShow] = useState(false);
  const [isResultScreenShow, setIsResultScreenShow] = useState(false);
  const [resultData, setResultData] = useState();
  const [ipInfo, setIPInfo] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIPInfo(res.data.ip);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  //For Android Mobile - White Strip Issue
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.body.setAttribute(
    "style",
    `height: calc(var(--vh, ${vh}px) * 100);`
  );
  // End Code
  
  return (
    <Container
      style={{
        backgroundImage: `url(${bg_app})`,
        backgroundSize: "cover",
        overflow: "auto",
      }}
    >
      <LinkedInTracking />
      <HeaderMenu />
      {!isInfoScreenShow && !isResultScreenShow && (
        <LandingScreen setIsInfoScreenShow={setIsInfoScreenShow} />
      )}
      {isInfoScreenShow && (
        <QuestionScreen
          setIsResultScreenShow={setIsResultScreenShow}
          setIsInfoScreenShow={setIsInfoScreenShow}
          setResultData={setResultData}
          ipInfo={ipInfo}
        />
      )}
      {/* {isResultScreenShow && (
        <ResultScreen
          setIsResultScreenShow={setIsResultScreenShow}
          setIsInfoScreenShow={setIsInfoScreenShow}
          resultData={resultData}
          ipInfo={ipInfo}
        />
      )} */}
    </Container>
  );
};

export default Home;
