import { css } from 'styled-components';

// Theme
export const HomeTheme = {
    name: 'Home',
    Container: css`
        width: 100%;
        height: 100%;
        // background-image: linear-gradient(180deg,#05336e,#c9eded);
    `,
    Wrapper: css`
        display: flex;
        width: 100%;
        height: 100%;
        @media (max-width: 991px) {
            display: block;
        }
    `,
    MobileRightContainer: css`
        display: none;
        @media (max-width: 991px) {
            display: block;
            margin: 0 auto;
        }
    `,
    LeftContainer: css`
        gap: 1.5rem;
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: auto;
        // @media (max-width: 991px) {
        //     margin-top: 62%;
        // }
        // @media (max-width: 767px) {
        //     margin-top: 73%;
        // }
        // @media (max-width: 576px) {
        //     margin-top: 90%;
        // }
    `,
    TitleWrapper: css`
        color: #ffffff;
    `,
    Title: css`
        font-size: 2.2vw;
        font-weight: 700;
        margin: 0px;
        white-space: pre-wrap;
        @media (max-width: 991px) {
            font-size: 1.5rem;
        }
    `,
    SubTitle: css`
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 1.2;
        width: 95%;
        color: ${(props) => props.theme.secondary};
        margin: 20px 0;
        @media (max-width: 1200px) {
            font-size: 1rem;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    `,
    ButtonWrapper: css`
        gap: 1.5rem;
        display: flex;
    `,
    Button: css`
        cursor: pointer;
        display: block;
        color: #615a79;
        background-color: #ffffff;
        border-color: #ffffff;
        box-shadow: none;
        border: 1px solid #615a79;
        padding: 0.875rem;
        font-size: 1rem;
        font-weight: 600;
        line-height: normal;
        border-radius: 0.5rem;
        min-width: 181px;
    `,
    DesktopRightContainer: css`
        gap: 2rem;
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 991px) {
            display: none;
            // width: auto;
            // position: absolute;
            // top: 13%;
            // right: 20%;
            // left: 20%;
        }
        @media (max-width: 576px) {
            right: 10%;
            left: 10%;
        }
    `,
    ImageOne: css`
        display: block;
        margin: 0 auto;
        @media (max-width: 767px) {
           width: 85%;
        }
    `,
}