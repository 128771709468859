import React from "react";
import styled from "styled-components";
import FrameLayout from "../../core/FrameLayout/FrameLayout";

const LeftContainer = styled.div`
   ${(props) => props.theme.Landing?.LeftContainer};
`;
const TitleWrapper = styled.div`
   ${(props) => props.theme.Landing?.TitleWrapper};
`;
const Title = styled.p`
   ${(props) => props.theme.Landing?.Title};
`;
const SubTitle = styled.p`
   ${(props) => props.theme.Landing?.SubTitle};
`;
const ButtonWrapper = styled.div`
   ${(props) => props.theme.Landing?.ButtonWrapper};
`;
const Button = styled.button`
   ${(props) => props.theme.Landing?.Button};
`;
const Wrapper = styled.div`
   ${(props) => props.theme.Landing?.Wrapper};
`;

const LandingScreen = (props) => {
   const { setIsInfoScreenShow } = props;

   const newline = (window.innerWidth > 576) ? '\n' : '';

   const handleNewHome = (event, id) => {
      // history(`/new-home`);
      setIsInfoScreenShow(true);
   };

   return (
      <FrameLayout className="row" marginBottom={'2rem'}>
         <Wrapper>
            <LeftContainer className="col col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6 col-xm-12">
               <TitleWrapper>
                  <Title>{'Use this AI powered tool to see how well' + newline + ' your scheme is doing in the marketplace.'}</Title>
                  <SubTitle>it's simple and fast</SubTitle>
               </TitleWrapper>
               <ButtonWrapper>
                  <Button type="button" onClick={handleNewHome}>Start</Button>
               </ButtonWrapper>
            </LeftContainer>
         </Wrapper>
      </FrameLayout>
   );
};

export default LandingScreen;