import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-form-wizard-component/dist/style.css";
import FrameLayout from "../../core/FrameLayout/FrameLayout";
import { useNavigate } from 'react-router-dom';

//https://react-form-wizard-component-document.netlify.app/docs/demos/validate-tab

const Card = styled.div`
   ${(props) => props.theme.Information?.Card};
`;
const CardHeader = styled.div`
   ${(props) => props.theme.Information?.CardHeader};
`;
const CardBody = styled.div`
   ${(props) => props.theme.Information?.CardBody};
`;
const RadioLabel = styled.label`
    ${(props) => props.theme.Information?.RadioLabel};
`;
const RadioButton = styled.input`
    ${(props) => props.theme.Information?.RadioButton};
`;
const RadioText = styled.span`
    ${(props) => props.theme.Information?.RadioText};
`;
const Question = styled.label`
   ${(props) => props.theme.Information?.Question};
`;
const Options = styled.div`
   ${(props) => props.theme.Information?.Options};
`;
const ButtonWrapper = styled.div`
   ${(props) => props.theme.Information?.ButtonWrapper};
`;
const Button = styled.button`
   ${(props) => props.theme.Information?.Button};
`;

const StrataHealthQuestions = [
    {
        id: 1,
        question: "Have you experienced any unexpected increases to your fees in the last 12 months?",
        input: 'no',
        option: [{ name: 'Yes', point: 0 }, { name: 'No', point: 10 }, { name: 'Unsure', point: 0 }],
        point: 0
    },
    {
        id: 2,
        question: "Does your current manager have multiple contact methods that suits your preferred way to communicate? (i.e. direct line, call centre, web chat, app chat, web forms, email)",
        input: 'no',
        option: [{ name: 'Yes', point: 10 }, { name: 'No', point: 5 }, { name: 'Unsure', point: 5 }],
        point: 0
    },
    {
        id: 3,
        question: "Do you get same day responses from your current manager?",
        input: 'no',
        option: [{ name: 'Yes', point: 10 }, { name: 'No', point: 5 }, { name: 'Unsure', point: 5 }],
        point: 0
    },
    {
        id: 4,
        question: "Do you have a dedicated Client Solutions team that you can call for assistance in addition to your Strata Manager?",
        input: 'no', option: [{ name: 'Yes', point: 20 }, { name: 'No', point: 0 }, { name: 'Unsure', point: 0 }],
        point: 0
    },
    {
        id: 5,
        question: "Do you have a specialised Strata App that gives you self-service and fingertip access to your levies, documents, and voting?",
        input: 'no',
        option: [{ name: 'Yes', point: 20 }, { name: 'No', point: 0 }, { name: 'Unsure', point: 0 }],
        point: 0
    },
    {
        id: 6,
        question: "Do you receive invitations to company events that promote community building as well as learning?",
        input: 'no',
        option: [{ name: 'Yes', point: 15 }, { name: 'No', point: 5 }, { name: 'Unsure', point: 5 }],
        point: 0
    },
    {
        id: 7,
        question: "Does your manager offer flexibility in hosting meetings virtually, in their offices, or onsite, including access to in-house conference rooms?",
        input: 'no',
        option: [{ name: 'Yes', point: 10 }, { name: 'No', point: 0 }, { name: 'Unsure', point: 0 }],
        point: 0
    },
    {
        id: 8,
        question: "Have you changed strata management in the last 24 months?",
        input: 'no',
        option: [{ name: 'Yes', point: 0 }, { name: 'No', point: 5 }, { name: 'Unsure', point: 0 }],
        point: 0
    },
    {
        id: 9,
        question: "Complete your details to view the results of your\nStrata Health Check",
        input: 'yes',
        option: [],
        point: 0
    }
];

const QuestionScreen = (props) => {
    const { ipInfo } = props;
    const [progressStatus, setProgressStatus] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(0);
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('Unsure');
    const [list, setList] = useState(StrataHealthQuestions);

    const [questionData, setQuestionData] = useState({
        question_1: {question: list[0].question, answer: list[0].option[2].name, point: list[0].option[2].point},
        question_2: {question: list[1].question, answer: list[1].option[2].name, point: list[1].option[2].point},
        question_3: {question: list[2].question, answer: list[2].option[2].name, point: list[2].option[2].point},
        question_4: {question: list[3].question, answer: list[3].option[2].name, point: list[3].option[2].point},
        question_5: {question: list[4].question, answer: list[4].option[2].name, point: list[4].option[2].point},
        question_6: {question: list[5].question, answer: list[5].option[2].name, point: list[5].option[2].point},
        question_7: {question: list[6].question, answer: list[6].option[2].name, point: list[6].option[2].point},
        question_8: {question: list[7].question, answer: list[7].option[2].name, point: list[7].option[2].point},
        name: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        try {
            if (StrataHealthQuestions) {
                setList(StrataHealthQuestions);
                // setErrorState(false);
            }
        } catch (err) {
            console.log(err);
            // setErrorState(true);
        }
    }, []);

    const handleNextQuestion = () => {

        let progress = parseFloat(progressStatus) + 11.12;
        const floatProgress = parseFloat(progress).toFixed(2);
        setProgressStatus(floatProgress);
        if (floatProgress >= 88.88) {
            navigate('/info', { state: { floatProgress, questionData, ipInfo } });
        } else {
            setQuestionIndex(parseInt(questionIndex) + 1);
            setSelectedOption('Unsure');
        }
    };

    const handlePrevQuestion = () => {
        setQuestionIndex(parseInt(questionIndex) - 1);

        let progress = parseFloat(progressStatus) - 11.12;
        setProgressStatus(parseFloat(progress).toFixed(2));

    }

    // Function to handle the change in selected option
    const handleQuestionAnsChange = (questionId, answerJson) => {
        setQuestionData({
            ...questionData, [questionId]: answerJson,
        });
    };

    const handleOptionChange = (question, option, point) => {
        const answerJson = {
            question: question,
            answer: option,
            point: point,
        };
        const newSelectedOption = selectedOption === option ? null : option;
        setSelectedOption(newSelectedOption);
        handleQuestionAnsChange(`question_${questionIndex + 1}`, answerJson);
    };

    return (
        <FrameLayout className="row" marginBottom={'2rem'} styles={{ marginTop: '8%', height: 'fit-content', position: 'relative' }}>
            <Card input={list[questionIndex].input}>
                <CardHeader>
                    <Question>{list[questionIndex].question}</Question>
                </CardHeader>
                <CardBody>
                    <Options>
                        <RadioLabel className="form-check font-weight-normal">
                            <RadioButton
                                type="checkbox"
                                name={`question_${questionIndex + 1}_option`}
                                id={`question_${questionIndex + 1}_yes`}
                                // checked={selectedOption.includes(list[questionIndex].option[0].point)}
                                checked={selectedOption === list[questionIndex].option[0].name}
                                value={list[questionIndex].option[0].point}
                                // onChange={() => handleOptionChange(list[questionIndex].option[0].name, list[questionIndex].option[0].point)}
                                onChange={() => handleOptionChange(list[questionIndex].question, list[questionIndex].option[0].name, list[questionIndex].option[0].point)}
                            />
                            <RadioText>Yes</RadioText>
                        </RadioLabel>
                        <RadioLabel className="form-check font-weight-normal">
                            <RadioButton
                                type="checkbox"
                                name={`question_${questionIndex + 1}_option`}
                                id={`question_${questionIndex + 1}_no`}
                                // checked={selectedOption.includes(list[questionIndex].option[1].point)}
                                checked={selectedOption === list[questionIndex].option[1].name}
                                value={list[questionIndex].option[1].point}
                                onChange={() => handleOptionChange(list[questionIndex].question, list[questionIndex].option[1].name, list[questionIndex].option[1].point)}
                            />
                            <RadioText>No</RadioText>
                        </RadioLabel>
                        <RadioLabel className="form-check font-weight-normal">
                            <RadioButton
                                type="checkbox"
                                name={`question_${questionIndex + 1}_option`}
                                id={`question_${questionIndex + 1}_unset`}
                                // checked={selectedOption.includes(list[questionIndex].option[2].point)}
                                checked={selectedOption === list[questionIndex].option[2].name}
                                value={list[questionIndex].option[2].point}
                                onChange={() => handleOptionChange(list[questionIndex].question, list[questionIndex].option[2].name, list[questionIndex].option[2].point)}
                            />
                            <RadioText>Unsure</RadioText>
                        </RadioLabel>
                    </Options>
                    <ButtonWrapper>
                        {questionIndex > 0 && (<Button type="button" onClick={() => handlePrevQuestion(progressStatus)}>Previous</Button>)}
                        <Button type="button" onClick={() => handleNextQuestion(progressStatus)}>Next</Button>
                    </ButtonWrapper>
                </CardBody>
                {/* add style */}
                <style>
                    {`
                     .form-control-error {
                        color: red;
                        font-size: 1rem;
                        padding: 0.2rem 0.5rem;
                     }
                     .error-astreisk {
                        color: red;
                        font-size: 20px;
                     }
                  `}
                </style>
            </Card>
        </FrameLayout>
    )
};

export default QuestionScreen;