import { HomeTheme } from "./homeTheme";
import { LandingTheme } from "./landingTheme";
import { InformationTheme } from "./informationTheme";
import { ResultsTheme } from "./resultsTheme";

const Themes = {
  Home: HomeTheme,
  Landing: LandingTheme,
  Information: InformationTheme,
  Results: ResultsTheme,
};

export default Themes;
